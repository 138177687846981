import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import ProxyService from 'api/services/ProxyService';
import { selectServices } from './services';

export const fetchProxyList = createAsyncThunk('fetchProxyList', async params => {
    const response = await ProxyService.search(params);
    return response.data;
});

export const fetchProxyFilterOptions = createAsyncThunk('fetchProxyFilterOptions', async () => {
    const response = await ProxyService.filterOptions();
    return response.data;
});

const ProxySlice = createSlice({
    name: 'ProxySlice',
    initialState: {
        proxyList: {
            loading: false,
            data: [],
            filterOptions: [],
            error: null,
            total: 0,
        },
        serviceList: [],
        selectedIDs: [],
    },
    reducers: {
        setIDs: (state, action) => {
            state.selectedIDs = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchProxyList.pending, state => {
                state.proxyList.loading = true;
            })
            .addCase(fetchProxyList.fulfilled, (state, action) => {
                state.proxyList.loading = false;
                state.proxyList.data = action.payload.data;
                state.proxyList.total = action.payload.total_count;
            })
            .addCase(fetchProxyList.rejected, (state, action) => {
                state.proxyList.loading = false;
                state.proxyList.error = action.error;
            })
            .addCase(fetchProxyFilterOptions.pending, state => {
                state.proxyList.loading = true;
            })
            .addCase(fetchProxyFilterOptions.fulfilled, (state, action) => {
                state.proxyList.loading = false;
                state.proxyList.filterOptions = action.payload.data;
            })
            .addCase(fetchProxyFilterOptions.rejected, (state, action) => {
                state.proxyList.loading = false;
                state.proxyList.error = action.error;
            });
    },
});

export const selectProxyList = createSelector([state => state.proxyState.proxyList], proxyList => proxyList.data);

export const selectProxyListTableData = createSelector([selectProxyList, selectServices], (data, services) =>
    data.map(proxy => ({
        id: proxy.proxy_id,
        service_id: proxy.service_id,
        ip_address: proxy.proxy_ip_address,
        port: proxy.proxy_http_port,
        proxy_http_port: proxy.proxy_http_port,
        proxy_socks5_port: proxy.proxy_socks5_port,
        proxy_username: proxy.proxy_username,
        proxy_password: proxy.proxy_password,
        country: {
            name: proxy.country_name,
            id: proxy.country_id,
        },
        users: services.find(i => i.service_id === proxy.service_id)?.proxy_user_ids || [],
        city: proxy.city_name,
        type: proxy.proxy_type,
        carrier: {
            id: `AS${proxy.asn_id}`,
            name: proxy.asn_name,
        },
        copy: `${proxy.proxy_ip_address}:${proxy.proxy_http_port}:${proxy.default_proxy_user_username}:${proxy.default_proxy_user_password}`,
    })),
);

export const selectProxyListLoading = createSelector(
    [state => state.proxyState.proxyList],
    proxyList => proxyList.loading,
);

export const selectProxyListTotal = createSelector([state => state.proxyState.proxyList], proxyList => proxyList.total);

export const selectProxyFilterOptions = createSelector(
    [state => state.proxyState.proxyList],
    proxyList => proxyList.filterOptions,
);

export const selectSelectedIDs = createSelector([state => state.proxyState.selectedIDs], selectedIDs => selectedIDs);

export const { setIDs } = ProxySlice.actions;

export default ProxySlice.reducer;
