import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { tableStyles } from './styles';
import './styles.scss';
import { getSortableStatus } from './helpers';
import CustomNoRowsOverlay from './NoData';
import { withoutEmptyValues } from 'helpers';

const getFilters = ({ paginationModel, sortModel, staticFilters, filters }) => {
    const data = {
        page: paginationModel.page + 1,
        per_page: paginationModel.pageSize,
    };

    if (sortModel?.[0]?.field && sortModel?.[0]?.sort) {
        data.sort_by = `${sortModel[0].field}_${sortModel[0].sort}`;
    }

    const mergedData = { ...staticFilters, ...filters, ...data };

    return withoutEmptyValues(mergedData);
};

const Table = ({
    columns,
    rows,
    rowHeight = 40,
    withoutPagination = false,
    withSelection = false,
    onRowSelectionModelChange = undefined,
    rowSelectionModel = undefined,
    fetchData = f => {},
    dataLoading,
    total,
    sortModel: sortModelInitial = [],
    noDataMessage = '',
    noDataComponent = null,
    staticFilters = {},
    filters = {},
    columnVisibilityModel = {},
    paginationMode = 'server',
    pageSize = 25,
    lastColSticky = false,
}) => {
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize });
    const [sortModel, setSortModel] = useState(sortModelInitial);

    const allFilters = {
        paginationModel,
        sortModel,
        staticFilters,
        filters,
    };

    const stringifiedFilters = JSON.stringify(filters);

    useEffect(() => {
        const timeout = setTimeout(() => {
            const filters = getFilters(allFilters);
            fetchData(filters);
        }, 100);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringifiedFilters]);

    const columnsWithSort = React.useMemo(
        () =>
            columns.map((col, i, arr) => ({
                ...col,
                sortable: getSortableStatus(col.field),
                cellClassName: lastColSticky && i === arr.length - 1 ? 'last-sticky-column' : '',
            })),
        [columns],
    );

    const isNoData = rows.length === 0;

    const onPaginationModelChange = model => {
        setPaginationModel(model);
        const filters = getFilters({ ...allFilters, paginationModel: model });
        fetchData(filters);
    };

    const onSortModelChange = model => {
        setSortModel(model);
        const filters = getFilters({ ...allFilters, sortModel: model });
        fetchData(filters);
    };

    if (noDataComponent && !rows.length && !dataLoading) {
        return <>{noDataComponent}</>;
    }

    return (
        <div className="custom-table">
            <DataGrid
                className={isNoData ? 'table-no-data' : ''}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
                columnVisibilityModel={columnVisibilityModel}
                checkboxSelection={withSelection}
                rowSelection={withSelection}
                onRowSelectionModelChange={onRowSelectionModelChange}
                rowSelectionModel={rowSelectionModel}
                columnHeaderHeight={40}
                rowHeight={rowHeight}
                rows={rows}
                columns={columnsWithSort}
                sx={tableStyles}
                hideFooter={withoutPagination}
                slots={{
                    noRowsOverlay: () => (
                        <CustomNoRowsOverlay
                            {...{
                                title: 'No search results found',
                                message: 'Try a different search query',
                                showMessage: true,
                            }}
                        />
                    ),
                    noResultsOverlay: () => (
                        <CustomNoRowsOverlay
                            {...{
                                title: noDataMessage || 'No search results found',
                                showMessage: false,
                            }}
                        />
                    ),
                }}
                paginationMode={paginationMode}
                paginationModel={paginationModel}
                pageSizeOptions={[10, 25, 50, 100]}
                onPaginationModelChange={onPaginationModelChange}
                pagination
                rowCount={total}
                loading={dataLoading}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
            />
        </div>
    );
};

export default Table;
