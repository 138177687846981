import React, { useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';

import ModalEditProxyUser from 'components/Modals/ModalEditProxyUser';
import ModalDeleteUserProxy from 'components/Modals/ModalDeleteUserProxy';
import Table from 'components/_common/Table';
import ProxyUserBandwidthUsageCell from 'components/_common/TableCells/ProxyUserBandwidthUsageCell';
import ListCell from 'components/_common/TableCells/ListCell';
import PasswordCell from 'components/_common/TableCells/PasswordCell';
import TextCell from 'components/_common/TableCells/TextCell';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/trash.svg';

const ProxyUserSettingsTable = ({ data, dataLoading, fetchData, total, noDataComponent, filters }) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [id, setID] = useState('');

    const columns = React.useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Proxy User ID',
                flex: 1,
                minWidth: 200,
                renderCell: ({ value, row }) => (
                    <TextCell
                        text={value}
                        labelText={row.proxy_user_is_default ? 'Default' : ''}
                        labelColor={'green'}
                    />
                ),
            },
            {
                field: 'password',
                headerName: 'Password',
                flex: 1,
                minWidth: 170,
                renderCell: ({ value }) => <PasswordCell value={value} />,
            },
            {
                field: 'whitelisted_ip',
                headerName: 'Whitelisted IP',
                flex: 1,
                minWidth: 120,
                renderCell: ({ value }) => <ListCell data={value} max_size={1} />,
            },
            {
                field: 'residential_bandwidth_usage',
                headerName: 'Residential Bandwidth Usage',
                flex: 0.5,
                minWidth: 230,
                renderCell: ({ value }) => <ProxyUserBandwidthUsageCell value={value.value} limit={value.limit} />,
            },
            {
                field: 'linked_products',
                headerName: 'Linked Products',
                flex: 1,
                minWidth: 180,
                renderCell: ({ value }) => <ListCell data={value} max_size={1} />,
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 50,
                width: 50,
                getActions: params => [
                    <GridActionsCellItem
                        showInMenu
                        label="Edit"
                        icon={<Edit />}
                        onClick={() => handleEditOpen(params)}
                    />,
                    <GridActionsCellItem
                        disabled={params.row.proxy_user_is_default}
                        showInMenu
                        className="item-danger"
                        label="Remove"
                        icon={<Delete />}
                        onClick={() => handleDeleteOpen(params)}
                    />,
                ],
            },
        ],
        [],
    );

    const handleDeleteOpen = params => {
        setID(params.id);
        setDeleteOpen(true);
    };

    const handleEditOpen = params => {
        setUserData(params.row);
        setEditOpen(true);
    };

    return (
        <>
            <Table
                lastColSticky
                columns={columns}
                rows={data}
                dataLoading={dataLoading}
                fetchData={fetchData}
                filters={filters}
                total={total}
                noDataComponent={noDataComponent}
            />
            <ModalDeleteUserProxy setOpen={setDeleteOpen} open={deleteOpen} id={id} />
            <ModalEditProxyUser setOpen={setEditOpen} open={editOpen} data={userData} />
        </>
    );
};

export default ProxyUserSettingsTable;
