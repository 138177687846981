import './styles.scss';

import { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectComponent from '@mui/material/Select';
import { FormLabel, ListSubheader, TextField } from '@mui/material';

import UntrolledCheckbox from '../Checkbox/UntrolledCheckbox';
import Badge from '../Badge';
import Label from '../Label';

import { getMenuProps } from './constants';

import { ReactComponent as Chevron } from 'icons/chevron-down.svg';

const Empty = () => <></>;

const MultipleSelect = ({
    label,
    options,
    value,
    onChange,
    width = 140,
    menuWidth = 'auto',
    fullWidth = false,
    withDisplayOptions = false,
    placeholder = '',
    loading = false,
    searchable = false,
}) => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredOptions = options.filter(item => item.label.toLowerCase().includes(searchQuery.toLowerCase()));

    const handleChange = event => {
        const {
            target: { value },
        } = event;

        onChange(typeof value === 'string' ? value.split(',') : value);
    };

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
    };

    const SelectedDisplay = ({ selected }) =>
        withDisplayOptions ? (
            <div className="custom-select-labels-group">
                {selected.length ? (
                    selected.map(value => (
                        <Label key={value} color="grey" text={options.find(item => item.value === value)?.label} />
                    ))
                ) : (
                    <span className="custom-select-labels-group__placeholder">
                        {loading ? 'Loading...' : placeholder}
                    </span>
                )}
            </div>
        ) : (
            <span className="custom-select__label">
                <span>{label}</span>
                <Badge value={selected.length} />
            </span>
        );

    const ChevronIcon = withDisplayOptions ? Empty : Chevron;
    const menuProps = { ...getMenuProps(menuWidth), disableAutoFocusItem: true };

    return (
        <FormControl
            sx={{ width: fullWidth ? '100%' : width }}
            className={`custom-select ${withDisplayOptions ? 'withDisplayOptions' : ''}`}
        >
            {withDisplayOptions && <FormLabel focused={false}>{label}</FormLabel>}
            <SelectComponent
                // @ts-ignore
                MenuProps={menuProps}
                size="small"
                multiple
                displayEmpty
                value={value}
                onChange={handleChange}
                input={<OutlinedInput />}
                IconComponent={ChevronIcon}
                renderValue={selected => <SelectedDisplay selected={selected} />}
                disabled={loading}
            >
                {searchable && (
                    <ListSubheader>
                        <TextField
                            className="custom-select__search-bar"
                            autoFocus
                            placeholder="Search..."
                            fullWidth
                            value={searchQuery}
                            onChange={handleSearchChange}
                            onKeyDown={e => e.stopPropagation()}
                            InputProps={{ style: { height: '2rem' } }}
                        />
                    </ListSubheader>
                )}
                {filteredOptions.map(item => (
                    <MenuItem className="custom-select__option" key={item.value} value={item.value}>
                        <UntrolledCheckbox checked={value.indexOf(item.value) > -1} />
                        <span className="custom-select__option-name">{item.label}</span>
                        {item.Label}
                    </MenuItem>
                ))}
            </SelectComponent>
        </FormControl>
    );
};

export default MultipleSelect;
