import '../styles.scss';

import { getPaymentsIcon, PAYMENT_METHODS } from 'helpers/payments';

const PaymentCell = ({ type, num, method = null }) => {
    const isCrypto = method === PAYMENT_METHODS.CRYPTO;

    if (!isCrypto && !num) {
        return false;
    }

    return (
        <div className="payment-cell">
            <div className="payment-cell__icon">{getPaymentsIcon(type, method)}</div>
            {isCrypto || (
                <div className="payment-cell__value">
                    <span className="card-dots" />
                    <span>{num}</span>
                </div>
            )}
        </div>
    );
};

export default PaymentCell;
