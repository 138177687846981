import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PaymentCell from '../PaymentCell';

import PaymentService from 'api/services/PaymentService';
import { pushMethodInfo, selectPayments } from 'store/slices/payments';

import { getPaymentPlatformIcon } from 'helpers/payments';

const PaymentCellByID = ({ id, transaction_processor }) => {
    const methods = useSelector(selectPayments);
    const dispatch = useDispatch();

    const getMethod = async () => {
        try {
            if (!id) return;
            const methodExists = methods.some(method => method.payment_method_id === id);
            if (!methodExists) {
                const response = await PaymentService.retrieve(id);
                dispatch(pushMethodInfo(response.data.data[0]));
            }
        } catch (e) {
            return false;
        }
    };

    useEffect(() => {
        getMethod();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { payment_method_network, payment_method_last_four } =
        methods.find(method => method.payment_method_id === id) || {};

    if (!id && transaction_processor) {
        return getPaymentPlatformIcon(transaction_processor);
    }

    return <PaymentCell type={payment_method_network} num={payment_method_last_four} />;
};

export default PaymentCellByID;
