import { ReactComponent as BitcoinIcon } from 'icons/colored/bitcoin.svg';
import { ReactComponent as TetherIcon } from 'icons/colored/tether.svg';
import { ReactComponent as EthereumIcon } from 'icons/colored/ethereum.svg';

const CryptoLogos = ({ withNumber = false }) => {
    return (
        <span className="radio-card-icons">
            <TetherIcon /> <BitcoinIcon /> <EthereumIcon /> {withNumber && '70+'}
        </span>
    );
};

export default CryptoLogos;
