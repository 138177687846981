import { useEffect } from 'react';

import RadioGroupCards from 'components/_common/RadioGroupCards';
import AllowedPaymentPlatforms from 'components/AllowedPaymentPlatforms';

import { getPaymentsOptions, payment_platform_types } from 'helpers/payments';

const BillingInfoCard = ({
    data,
    paymentPlatform,
    setPaymentPlatform,
    disabled = false,
    paymentMethod,
    setPaymentMethod,
    paymentCoin,
    setPaymentCoin,
}) => {
    const {
        allowed_payment_platforms,
        allowed_saved_payment_methods,
        allowed_coinpayments_currencies,
        credit_only_checkout,
    } = data;
    const isSavedPaymentsAllowed = process.env.REACT_APP_ALLOW_SAVED_PM_CHECKOUT === 'TRUE';
    const payment_options = isSavedPaymentsAllowed ? getPaymentsOptions(allowed_saved_payment_methods) : [];
    const coins_options = allowed_coinpayments_currencies.map(item => ({ value: item, label: item }));
    const showCard =
        (paymentPlatform === payment_platform_types.stripe || paymentPlatform === payment_platform_types.CARD) &&
        !!payment_options.length;
    const showCoin = paymentPlatform === payment_platform_types.coinpayments && !!coins_options.length;

    useEffect(() => {
        if (isSavedPaymentsAllowed && allowed_saved_payment_methods) setPaymentMethod(payment_options[0]?.value);
        if (allowed_coinpayments_currencies) setPaymentCoin(coins_options[0]?.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="box">
                <h4 className="mb-24">Billing information</h4>
                <AllowedPaymentPlatforms
                    allowed_payment_platforms={allowed_payment_platforms}
                    credit_only_checkout={credit_only_checkout}
                    value={paymentPlatform}
                    onChange={setPaymentPlatform}
                    disabled={disabled}
                />
            </div>
            {showCard && (
                <div className="box">
                    <h4 className="mb-24">Select payment card</h4>
                    <RadioGroupCards
                        disabled={disabled}
                        label="Select payment card"
                        items={payment_options}
                        value={paymentMethod}
                        onChange={setPaymentMethod}
                    />
                </div>
            )}
            {showCoin && (
                <div className="box">
                    <h4 className="mb-24">Select payment coin</h4>
                    <p className="body1 mb-24">
                        If paying with cryptocurrency, your order will be delivered once the transaction is fully
                        confirmed on your selected blockchain. Time for this can vary substantially.
                    </p>
                    <RadioGroupCards
                        disabled={disabled}
                        label="Select payment coin"
                        items={coins_options}
                        value={paymentCoin}
                        onChange={setPaymentCoin}
                    />
                </div>
            )}
        </>
    );
};

export default BillingInfoCard;
