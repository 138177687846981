import { ReactComponent as VisaIcon } from 'icons/colored/visa.svg';
import { ReactComponent as MastercardIcon } from 'icons/colored/mastercard.svg';
import { ReactComponent as AmexIcon } from 'icons/colored/american-express.svg';
import { ReactComponent as DiscoverIcon } from 'icons/colored/discover.svg';

const CreditCardLogos = () => {
    return (
        <span className="radio-card-icons">
            <VisaIcon /> <MastercardIcon /> <AmexIcon /> <DiscoverIcon />
        </span>
    );
};

export default CreditCardLogos;
