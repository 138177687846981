import Label from 'components/_common/Label';

export const getTarrifName = (interval, count) => {
    if (interval === 'day') {
        return `${count > 1 ? `${count} days` : 'Daily'}`;
    }
    if (interval === 'week') {
        return `${count > 1 ? `${count} weeks` : 'Weekly'}`;
    }
    if (interval === 'month') {
        return `${count > 1 ? (count === 3 ? 'Quarterly' : `${count} months`) : 'Monthly'}`;
    }
    if (interval === 'year') {
        return `${count > 1 ? `${count} years` : 'Annual'}`;
    }
};

export const getPeriodValueInMonths = (interval, count) => {
    switch (interval) {
        case 'day':
            return count / 31;
        case 'week':
            return count / 4;
        case 'month':
            return count;
        case 'year':
            return count * 12;
    }
};

export const convertToDollar = (value = 0) => Number((value / 100).toFixed(2));

export const getPriceTierAmount = (value, data) => {
    for (let i = 0; i < data?.length; i++) {
        const tier = data[i];

        if (tier.price_tier_up_to === null || value <= tier.price_tier_up_to) {
            return tier.price_tier_amount;
        }
    }

    return data[data?.length - 1]?.price_tier_amount;
};

export function getPriceTiers(priceTiers, maxValue) {
    let priceRanges = [];

    for (let i = 0; i < priceTiers.length; i++) {
        let range = {};
        if (i === 0) {
            range.from = 1;
            range.to = priceTiers[i].price_tier_up_to;
        } else {
            range.from = priceTiers[i - 1].price_tier_up_to;
            range.to = priceTiers[i].price_tier_up_to || maxValue;
        }
        range.amount = priceTiers[i].price_tier_amount;
        priceRanges.push(range);
    }

    return priceRanges;
}

export const sortPrices = prices => {
    const intervalOrder = { day: 1, week: 2, month: 3, year: 4 };

    return [...prices].sort((a, b) => {
        const intervalComparison = intervalOrder[a.price_cycle_interval] - intervalOrder[b.price_cycle_interval];
        if (intervalComparison !== 0) return intervalComparison;

        return a.price_cycle_interval_count - b.price_cycle_interval_count;
    });
};

export const getPeriodOptions = prices => {
    return sortPrices(prices)
        .map((price, index) => ({
            value: String(index),
            label: getTarrifName(price?.price_cycle_interval, price?.price_cycle_interval_count),
            price_id: price?.price_id,
            price_cycle_interval: price?.price_cycle_interval,
            price_cycle_interval_count: price?.price_cycle_interval_count,
            labelPosition: 'end',
            Label:
                price?.price_cycle_interval === 'year' && price?.price_cycle_interval_count === 1 ? (
                    <Label text="Best Value" color="purple" />
                ) : price?.price_cycle_interval === 'month' && price?.price_cycle_interval_count === 3 ? (
                    <Label text="10% OFF" color="green" />
                ) : null,
        }))
        .filter(option => option?.price_cycle_interval && option?.price_cycle_interval_count);
};

export const getPeriodName = data => {
    return getTarrifName(data?.price_cycle_interval, data?.price_cycle_interval_count);
};

export const getPeriodValue = data => {
    return getPeriodValueInMonths(data?.price_cycle_interval, data?.price_cycle_interval_count);
};

export const getPriceIDByCoutryCode = (allProducts, price_data, country_id, product_type) => {
    const { price_cycle_interval, price_cycle_interval_count } = price_data;

    return allProducts
        .find(p => p.country_id === country_id && p.product_is_active && p.product_type === product_type)
        ?.product_prices.find(
            price =>
                price.price_cycle_interval === price_cycle_interval &&
                price.price_cycle_interval_count === price_cycle_interval_count,
        ).price_id;
};