import { getPeriodOptions } from 'pages/Product/helpers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment } from '@mui/material';
import { isUndefined } from 'lodash';

import ServiceDataLine from 'components/ServiceDataLine';
import TextInput from 'components/_common/TextInput';
import SimpleSelect from 'components/_common/Select/SimpleSelect';
import RemoveProxiesDropdown from './RemoveProxiesDropdown';

import { fetchProxiesForDropdown, selectImmediateData, setReconfigureParams } from 'store/slices/reconfigureService';

import { IP, GB } from 'helpers/constants';
import { service_types } from 'helpers/services';

const ReconfigureTab = ({ data }) => {
    const dispatch = useDispatch();
    const reconfigureData = useSelector(selectImmediateData);
    const { product_prices, min_service_quantity, max_service_quantity, require_remove_proxy_ids } = reconfigureData;
    const { service_price_id, service_quantity, id, service, service_cycle, period: period_cycle } = data;
    const period_options = getPeriodOptions(product_prices);
    const cycle = period_cycle ? period_cycle : service_cycle;
    const serviceCycleIntervalCount = +cycle?.split(period_cycle ? ' ' : ':')[0];
    const serviceCycleInterval = cycle?.split(period_cycle ? ' ' : ':')[1]?.toLowerCase();
    const initialPeriod = period_options?.find(
        period =>
            period?.price_cycle_interval === serviceCycleInterval &&
            period?.price_cycle_interval_count === serviceCycleIntervalCount,
    )?.value;
    const isResidential = service?.type === service_types?.residential;

    const [remove_proxy_ids, setProxyIDs] = useState([]);
    const [period, changePeriod] = useState(initialPeriod);
    const [serviceQuantity, setServiceQuantity] = useState(service_quantity);

    const showDropdown = require_remove_proxy_ids && serviceQuantity < service_quantity;

    useEffect(() => {
        if (require_remove_proxy_ids) {
            // @ts-ignore
            dispatch(fetchProxiesForDropdown(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const selectedPriceID = period_options.find(item => item.value === period)?.price_id;

        const empty = remove_proxy_ids.length === 0;
        const tooMuch = remove_proxy_ids.length > service_quantity - serviceQuantity;
        const notEnough = remove_proxy_ids.length < service_quantity - serviceQuantity;

        const disabled =
            (showDropdown && (empty || tooMuch || notEnough)) ||
            (service_quantity === serviceQuantity && service_price_id === selectedPriceID) ||
            serviceQuantity < min_service_quantity ||
            serviceQuantity > max_service_quantity;

        const params = { end_of_period: false, service_id: id, disabled };

        if (service_quantity !== serviceQuantity) {
            params.service_quantity = serviceQuantity;
        }
        if (showDropdown && remove_proxy_ids.length) {
            params.remove_proxy_ids = remove_proxy_ids.map(item => String(item));
        }
        if (service_price_id !== selectedPriceID && !isUndefined(selectedPriceID)) {
            params.service_price_id = selectedPriceID;
        }

        dispatch(setReconfigureParams(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceQuantity, remove_proxy_ids, period]);

    return (
        <div>
            <ServiceDataLine type={service.type} name={service.name} id={service.id} />
            <SimpleSelect
                withRadio
                fullWidth
                withFormLabel
                label="Subscription cycle"
                options={period_options}
                value={period}
                onChange={changePeriod}
            />
            <div className="mt-24">
                <TextInput
                    type="number"
                    label="New Quantity"
                    min={min_service_quantity}
                    max={max_service_quantity}
                    value={serviceQuantity}
                    onChange={setServiceQuantity}
                    endAdornment={<InputAdornment position="end">{isResidential ? GB : IP}</InputAdornment>}
                />
                <div className="description mt-8">{`You can enter between ${min_service_quantity} and ${max_service_quantity} ${
                    isResidential ? GB + 's' : IP + 's'
                }`}</div>
            </div>
            {showDropdown && (
                <RemoveProxiesDropdown
                    onChange={setProxyIDs}
                    value={remove_proxy_ids}
                    serviceQuantity={serviceQuantity}
                    service_quantity={service_quantity}
                />
            )}
        </div>
    );
};

export default ReconfigureTab;
