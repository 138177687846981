import './styles.scss';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import List from 'components/_common/List';
import Button from 'components/_common/Button';
import DiscountCodeForm from 'components/DiscountCodeForm';
import PaymentsMethods from './Items/PaymentsMethods';
import SidebarRow from './Items/SidebarRow';
import SidebarDivider from './Items/SidebarDivider';
import DiscountLabel from './DiscountLabel';

import { selectCalculationInfo } from 'store/slices/products';
import { service_types } from 'helpers/services';
import { getOrdinalSuffix } from 'helpers';

const items = ['A* Customer Support', 'Clean IP Addresses'];

const scrollTop = () =>
    window.scrollTo({
        top: document?.querySelector('main')?.getBoundingClientRect().top,
        behavior: 'smooth',
    });

const OrderSummarySidebar = ({
    onSubmit,
    state,
    quantityText,
    quantityValue,
    initialPrice,
    finalPrice,
    pricePerUnit,
    productType,
    location,
    loadingCode,
    loadingSubmit,
    name,
    periodName,
    periodValueInMonths,
    discountCode,
    onChangeDiscount,
    discount,
    discountError,
    onDiscountSubmit,
    disabled,
    isResidentialReconfigure,
}) => {
    const {
        total_after_applied_credit,
        customer_credit_balance_applied_amount,
        customer_credit_balance_applied,
        credit_balance,
        has_data,
        before_discount_total,
        discounted,
    } = useSelector(selectCalculationInfo);

    const isResidential = productType === service_types.residential;

    const handleSubmit = () => {
        scrollTop();
        onSubmit();
    };

    const priceLabel = `Monthly price per ${isResidential ? 'GB' : 'IP'}`;

    const total = `$${(discount * finalPrice).toFixed(2)}`;

    // const saving = `${Math.round((1 - finalPrice / quantityValue / periodValueInMonths / initialPrice) * 100)}%`;

    const monthlyPricePerUnit = (pricePerUnit / periodValueInMonths).toFixed(2);

    const nextMonthDatetime = dayjs().add(1, 'month');
    const nextMonthDay = nextMonthDatetime.date();
    const nextMonth = nextMonthDatetime.format('MMMM');
    const residentialNextRenewalDate = `${nextMonthDay}${getOrdinalSuffix(nextMonthDay)} ${nextMonth}`;

    return (
        <div className="order-summary-sidebar-container">
            <div className="order-summary box">
                <h5>{isResidentialReconfigure ? 'Reconfiguration Summary' : 'Order summary'}</h5>
                <div className="subtitle1 pb-8">{name}</div>
                <List items={items} className="pb-16" />
                {isResidential && <SidebarRow name="Next Renewal Date">{residentialNextRenewalDate}</SidebarRow>}
                <SidebarRow name="Quantity">{quantityText}</SidebarRow>
                {!isResidential && <SidebarRow name="Location">{location?.label}</SidebarRow>}
                <SidebarRow name={priceLabel}>${monthlyPricePerUnit}</SidebarRow>
                <SidebarRow name="Subscription period">{periodName}</SidebarRow>
                {state === 1 && !isResidentialReconfigure && (
                    <DiscountCodeForm
                        loading={loadingCode}
                        value={discountCode}
                        onChange={onChangeDiscount}
                        error={discountError}
                        onSubmit={onDiscountSubmit}
                        disabled={discount < 1 || loadingSubmit}
                    />
                )}
                <SidebarDivider />
                {discounted && <SidebarRow name="Before discount total">{before_discount_total}</SidebarRow>}
                {discount < 1 && (
                    <SidebarRow name={<DiscountLabel discount={discount} discountCode={discountCode} />}>
                        -${(finalPrice - finalPrice * discount).toFixed(2)}
                    </SidebarRow>
                )}
                {has_data && <SidebarRow name="Credit balance">{credit_balance}</SidebarRow>}
                {customer_credit_balance_applied && (
                    <SidebarRow name="Credit balance applied amount">
                        {customer_credit_balance_applied_amount}
                    </SidebarRow>
                )}
                <SidebarRow name="Total">{has_data ? total : <h4>{total}</h4>}</SidebarRow>
                {/* <SidebarRow name="Saving">{has_data ? saving : <h4>{saving}</h4>}</SidebarRow> */}
                {total_after_applied_credit && has_data && (
                    <SidebarRow name="Total after applied credit">
                        <h4>{total_after_applied_credit}</h4>
                    </SidebarRow>
                )}
            </div>
            {state < 3 && (
                <div className="order-submit-container">
                    <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleSubmit}
                        loading={loadingSubmit}
                        disabled={loadingCode || disabled}
                    >
                        {isResidentialReconfigure ? 'Calculate Top Up' : 'Continue to checkout'}
                    </Button>
                    <PaymentsMethods />
                </div>
            )}
        </div>
    );
};

export default OrderSummarySidebar;
