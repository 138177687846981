import ControledDialog from 'components/_common/Dialog/ControledDialog';

import { ReactComponent as Bad } from 'icons/colored/alert-triangle-filled.svg';

const ModalGenerateProxyWarning = ({ setOpen, open }) => {
    return (
        <ControledDialog setOpen={setOpen} open={open} withClose={false} cancelText={'Okay'}>
            <div
                className={`message-text  message-text--small`}
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
                <div style={{ display: 'flex', gap: 10 }} className="message-text--bad">
                    <Bad />
                    <div>Warning</div>
                </div>
                <div className="message-text__text" style={{ paddingLeft: 30 }}>
                    We recommend leaving City and Internet Provider set to Automatic unless required by your use-case.
                    Using ASN and City selection can restrict available pool size which could lead to degraded
                    performance.
                </div>
            </div>
        </ControledDialog>
    );
};

export default ModalGenerateProxyWarning;
