import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from 'components/_common/Autocomplete';

import {
    fetchCountries,
    selectCountriesOptions,
    fetchCountriesDropdown,
    selectPopularCountriesOptions,
} from 'store/slices/customer';

const CountriesDropdown = ({ value, onChange }) => {
    const dispatch = useDispatch();
    const options = useSelector(selectCountriesOptions);
    const extraOptions = useSelector(selectPopularCountriesOptions);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCountries({ per_page: 500 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Autocomplete
            extraOptions={extraOptions}
            extraOptionsTitle="Popular"
            fullWidth
            withFormLabel
            label="Country"
            value={value}
            options={options}
            onChange={onChange}
            fetchData={query => fetchCountriesDropdown({ like_country_name: `%${query}%` })}
        />
    );
};

export default CountriesDropdown;
