const Flag = ({ country_id, size = 20 }) => {
    const code = String(country_id);

    if (country_id) {
        return (
            <img
                className="country-icon"
                loading="lazy"
                width={size}
                srcSet={`https://flagcdn.com/${code}.svg 2x`}
                src={`https://flagcdn.com/${code}.svg`}
                alt=""
            />
        );
    } else {
        return null;
    }
};

export default Flag;
