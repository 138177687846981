import List from 'components/_common/List';

const regularItems = {
    title: 'All plans include',
    points: [
        'Unlimited Concurrent Sessions',
        'Country Level Targeting',
        'HTTP and SOCKS5 support',
        'Proxy Servers in 195+ Countries',
        'Email and Chat Support',
        'API access (Launching Soon)',
    ],
};

const reconfigureItems = {
    title: 'Understanding Top Ups & Reconfiguration',
    points: [
        'Bandwidth will be credited to your account immediately',
        'Your renewal date and quantity will be updated',
    ],
};

const PlansInfoCard = ({ isResidentialReconfigure }) => (
    <div className="box">
        <h6 className="pb-16">{isResidentialReconfigure ? reconfigureItems.title : regularItems.title}</h6>
        <List items={isResidentialReconfigure ? reconfigureItems.points : regularItems.points} columns={2} />
    </div>
);

export default PlansInfoCard;
