import { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormLabel, InputAdornment } from '@mui/material';

import Badge from '../Badge';
import UntrolledCheckbox from '../Checkbox/UntrolledCheckbox';
import Label from 'components/_common/Label';

import { ReactComponent as Chevron } from 'icons/chevron-down.svg';

const AutocompleteComponent = ({
    label,
    fetchData = null,
    value,
    onChange,
    width = 140,
    fullWidth = false,
    withFormLabel = false,
    disabled = false,
    multiple = false,
    options: initialOptions,
    extraOptions = null,
    extraOptionsTitle = '',
    optionCustomStyle = {},
    disableClearable = false,
}) => {
    const [internalValue, setInternalValue] = useState(value);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const selectedOption = multiple
        ? options.filter(option => internalValue?.some(val => val.value === option.value))
        : options.find(option => option.value === internalValue?.value);

    const handleChange = (event, newValue) => {
        setInternalValue(newValue);
        if (multiple) {
            onChange(newValue?.map(i => i?.value || i) || null);
        } else {
            onChange(newValue?.value || null);
        }
    };

    const fetchOptions = async query => {
        setLoading(true);
        try {
            const response = await fetchData(query);
            const newOptions = [
                ...(extraOptions ? extraOptions.map(opt => ({ ...opt, group: extraOptionsTitle })) : []),
                ...(response ? response.map(opt => ({ ...opt, group: extraOptions?.length ? 'All' : '' })) : []),
            ];
            setOptions(newOptions);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event, value) => {
        if (fetchData) {
            fetchOptions(value);
        }
    };

    useEffect(() => {
        if (!value) {
            return setInternalValue(null);
        }
        if (value !== internalValue && value !== internalValue?.value) {
            setInternalValue(value);
        }
    }, [value]);

    useEffect(() => {
        const newOptions = [
            ...(extraOptions ? extraOptions.map(opt => ({ ...opt, group: extraOptionsTitle })) : []),
            ...(initialOptions
                ? initialOptions.map(opt => ({ ...opt, group: extraOptions?.length ? 'All' : '' }))
                : []),
        ];
        setOptions(newOptions);
    }, [initialOptions, extraOptions, extraOptionsTitle]);

    return (
        <FormControl sx={{ width: fullWidth ? '100%' : width }} className="custom-select">
            {withFormLabel && <FormLabel focused={false}>{label}</FormLabel>}
            <Autocomplete
                multiple={multiple}
                options={options}
                size="small"
                disableClearable={disableClearable}
                onChange={handleChange}
                onInputChange={handleInputChange}
                value={internalValue}
                disabled={disabled}
                loading={loading}
                disableCloseOnSelect={multiple}
                isOptionEqualToValue={(option, value) => option.value === value?.value || option.value === value}
                groupBy={option => option.group}
                renderGroup={params => (
                    <li key={params.key}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.1rem', padding: '5px 10px' }}>
                            {params.group}
                        </div>
                        <ul style={{ paddingLeft: 0 }}>{params.children}</ul>
                    </li>
                )}
                limitTags={0}
                renderTags={() => null}
                renderOption={(props, option, state) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li style={optionCustomStyle} key={key} {...optionProps}>
                            {multiple && (
                                <div style={{ minWidth: 42, width: 42 }}>
                                    <UntrolledCheckbox checked={state.selected} />
                                </div>
                            )}
                            {option.label}
                            {option.showLabel && (
                                <div style={{ marginLeft: 10, display: 'flex' }}>
                                    <Label color={option.labelColor} text={option.labelText} />
                                </div>
                            )}
                        </li>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        placeholder={label}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: 'auto',
                                paddingTop: '4px !important',
                                paddingBottom: '5px !important',
                            },
                            '& .MuiInputBase-input': {
                                height: 'auto',
                                padding: 0,
                            },
                            '& .MuiButtonBase-root': {
                                visibility: 'visible',
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: 'rgb(31, 42, 55)',
                                opacity: 1,
                                fontWeight: 500,
                                fontFamily: 'Space Grotesk,sans-serif',
                                fontSize: '14px',
                            },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {multiple && internalValue?.length > 0 && (
                                        <InputAdornment position="end">
                                            <Badge value={internalValue.length} />
                                        </InputAdornment>
                                    )}
                                    {!multiple && selectedOption?.showLabel && (
                                        <InputAdornment position="end">
                                            <Label color={selectedOption.labelColor} text={selectedOption.labelText} />
                                        </InputAdornment>
                                    )}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                popupIcon={<Chevron />}
            />
        </FormControl>
    );
};

export default AutocompleteComponent;
