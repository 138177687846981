import '../styles.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ModalPauseUnpauseService from 'components/Modals/ModalPauseUnpauseService';
import InfoBanner from 'components/InfoBanner';
import MessageCard from 'components/MessageCard';
import GenerateProxyForm from '../items/GenerateProxyForm';
import TerminalProxies from '../items/TerminalProxies';

import { fetchResidentialSummary, selectMetrics } from 'store/slices/services';
import { selectActiveResidentialServiceId } from 'store/slices/customer';

import ROUTES from 'helpers/routes';
import { service_types } from 'helpers/services';

const ConfiguratorTab = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const metrics = useSelector(selectMetrics);
    const active_residential_service_id = useSelector(selectActiveResidentialServiceId);
    const [isUnpauseModalOpen, setIsUnpauseModalOpen] = useState(false);

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchResidentialSummary());
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {+metrics?.find(metric => metric.title === 'usage')?.isNoBandwidth ? (
                <div className="mb-24">
                    <MessageCard
                        buttonText="Buy Now"
                        customButtonStyles={{ display: 'flex', width: 200, marginLeft: 'auto' }}
                        type="bad"
                        message="You don’t have any residential bandwidth."
                        to={`${ROUTES.products}/${service_types.residential}`}
                    />
                </div>
            ) : null}
            {metrics?.find(metric => metric.title === 'usage')?.isNeedTopUp ? (
                <div className="mb-24">
                    <MessageCard
                        buttonText="Top Up"
                        customStyles={{ display: 'flex', gap: 5, height: 100 }}
                        customButtonStyles={{ display: 'block', width: 200, marginLeft: 'auto' }}
                        type="bad"
                        message="You are close to running out of bandwidth, top up now to continue using Residential proxies."
                        onClick={() => navigate(`${ROUTES.products}/${service_types.residential}`)}
                    />
                </div>
            ) : null}
            <div className="configurator-tab">
                <GenerateProxyForm />
                <TerminalProxies />
            </div>
            <InfoBanner article={process.env.REACT_APP_API_DOCUMENTATION_RESIDENTIAL_LINK} />
            <ModalPauseUnpauseService
                open={isUnpauseModalOpen}
                setOpen={setIsUnpauseModalOpen}
                customContent={<p>Service is paused. Please unpause the service to reconfigure.</p>}
                service_id={active_residential_service_id}
                onClick={() => navigate(`${ROUTES.products}/${service_types.residential}`)}
            />
        </div>
    );
};

export default ConfiguratorTab;
