import React, { useEffect, useState } from 'react';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import BillingInfoCard from 'components/BillingInfoCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchSingleBillingInvoicePayOptions,
    selectSingleBillingInvoiceLoading,
    selectSingleBillingInvoicePayOptions,
} from 'store/slices/billingInvoices';
import Spinner from 'components/_common/Spinner';
import ConfirmButton from 'components/Buttons/ConfirmButton';
import { withoutEmptyValues } from 'helpers';
import { filterPaymentParamsByPlatform } from 'helpers/services';
import InvoicesService from 'api/services/InvoicesService';
import { PAYMENT_METHODS } from 'helpers/payments';

const ModalPay = ({ isPayModalOpen, setIsPayModalOpen, invoiceId }) => {
    const dispatch = useDispatch();
    const invoiceLoading = useSelector(selectSingleBillingInvoiceLoading);
    const invoicePayOptions = useSelector(selectSingleBillingInvoicePayOptions);

    const [paymentPlatform, setPaymentPlatform] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentCoin, setPaymentCoin] = useState('');
    const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);

    const prefferedCardPlatform = process.env.REACT_APP_PREFFERED_CARD_PROCESSOR;
    const prefferedCryptoPlatform = process.env.REACT_APP_PREFFERED_CRYPTO_PROCESSOR;

    const handlePayInvoice = async () => {
        if (invoiceId) {
            try {
                setPaymentLinkLoading(true);
                const params = withoutEmptyValues({
                    coinpayments_currency: paymentCoin,
                    payment_method_id: paymentMethod,
                    payment_platform:
                        paymentPlatform === PAYMENT_METHODS.CARD
                            ? prefferedCardPlatform
                            : paymentPlatform === PAYMENT_METHODS.CRYPTO
                            ? prefferedCryptoPlatform
                            : paymentPlatform,
                });
                const filteredParams = filterPaymentParamsByPlatform(params.payment_platform, params);
                const response = await InvoicesService.generatePaymentLink(invoiceId, filteredParams);
                const redirect_url = response?.data?.data?.redirect_url;
                if (redirect_url) {
                    window.open(redirect_url, '_blank');
                }
            } catch (error) {
                return false;
            } finally {
                setPaymentLinkLoading(false);
                setIsPayModalOpen(false);
            }
        }
    };

    useEffect(() => {
        if (invoiceId) {
            // @ts-ignore
            dispatch(fetchSingleBillingInvoicePayOptions(invoiceId));
            //
        }
    }, [invoiceId]);

    return (
        <ControledDialog
            title="Pay invoice"
            Submit={<ConfirmButton onClick={handlePayInvoice} loading={invoiceLoading} />}
            setOpen={setIsPayModalOpen}
            open={isPayModalOpen}
            minHeight={750}
        >
            {invoiceLoading || paymentLinkLoading ? (
                <Spinner />
            ) : (
                <BillingInfoCard
                    data={invoicePayOptions}
                    paymentPlatform={paymentPlatform}
                    setPaymentPlatform={setPaymentPlatform}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    paymentCoin={paymentCoin}
                    setPaymentCoin={setPaymentCoin}
                />
            )}
        </ControledDialog>
    );
};

export default ModalPay;
