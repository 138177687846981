import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import TransactionsService from 'api/services/TransactionsService';

export const fetchTransactions = createAsyncThunk(
    'fetchTransactions',
    async params => {
        const response = await TransactionsService.transactionsSearch(params);
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            // @ts-ignore
            const { loading } = getState().billingTransactionsState.transactions;
            return !loading;
        },
    },
);

export const fetchBalanceTransactions = createAsyncThunk(
    'fetchBalanceTransactions',
    async params => {
        const response = await TransactionsService.balanceTransactionSearch(params);
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            // @ts-ignore
            const { loading } = getState().billingTransactionsState.balance_transactions;
            return !loading;
        },
    },
);

const billingTransactionsSlice = createSlice({
    name: 'billingTransactionsSlice',
    initialState: {
        transactions: {
            loading: false,
            data: [],
            total: 0,
        },
        balance_transactions: {
            loading: false,
            data: [],
            total: 0,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchTransactions.pending, state => {
                state.transactions.loading = true;
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.transactions.loading = false;
                state.transactions.data = action.payload.data;
                state.transactions.total = action.payload.total_count;
            });
        builder
            .addCase(fetchBalanceTransactions.pending, state => {
                state.balance_transactions.loading = true;
            })
            .addCase(fetchBalanceTransactions.fulfilled, (state, action) => {
                state.balance_transactions.loading = false;
                state.balance_transactions.data = action.payload.data;
                state.balance_transactions.total = action.payload.total_count;
            });
    },
});

export const selectTransactions = createSelector(
    [state => state.billingTransactionsState.transactions],
    transactions => transactions.data,
);

export const selectTransactionsLoading = createSelector(
    [state => state.billingTransactionsState.transactions],
    transactions => transactions.loading,
);

export const selectTransactionsTotal = createSelector(
    [state => state.billingTransactionsState.transactions],
    transactions => transactions.total,
);

export const selectTransactionsTable = createSelector([selectTransactions], data =>
    data
        .map(tr => ({
            id: tr.transaction_id,
            invoice_id: tr.invoice_id,
            transaction_id: tr.transaction_id,
            date: tr.transaction_creation_datetime,
            transaction_creation_datetime: tr.transaction_creation_datetime,
            amount: tr.transaction_amount,
            transaction_method: tr.transaction_method,
            transaction_processor: tr.transaction_processor,
            status: tr.transaction_status,
            payment_method_id: tr.payment_method_id,
        }))
        .toReversed(),
);

export const selectBalanceTransactions = createSelector(
    [state => state.billingTransactionsState.balance_transactions],
    balance_transactions => balance_transactions.data,
);

export const selectBalanceTransactionsTable = createSelector([selectBalanceTransactions], data =>
    data
        .map(tr => ({
            id: tr.balance_transaction_id,
            balance_transaction_id: tr.balance_transaction_id,
            balance_transaction_reason: tr.balance_transaction_reason,
            invoice_id: tr.invoice_id,
            date: tr.balance_transaction_creation_datetime,
            balance_transaction_creation_datetime: tr.balance_transaction_creation_datetime,
            amount: tr.balance_transaction_amount,
        }))
        .toReversed(),
);

export const selectBalanceTransactionsLoading = createSelector(
    [state => state.billingTransactionsState.balance_transactions],
    balance_transactions => balance_transactions.loading,
);

export const selectBalanceTransactionsTotal = createSelector(
    [state => state.billingTransactionsState.balance_transactions],
    balance_transactions => balance_transactions.total,
);

export default billingTransactionsSlice.reducer;
