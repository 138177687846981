import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BillingLayout from '../Layout';
import InvoicesTable from './Table';

import {
    selectBillingInvoicesLoading,
    selectBillingInvoicesTotal,
    selectBillingInvoicesTable,
    fetchBillingInvoices,
} from 'store/slices/billingInvoices';
import { fetchBillingOrders } from 'store/slices/billingOrders';

const Invoices = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectBillingInvoicesTable);
    const loading = useSelector(selectBillingInvoicesLoading);
    const total = useSelector(selectBillingInvoicesTotal);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchBillingOrders());
    }, []);

    return (
        <BillingLayout>
            <InvoicesTable
                data={data}
                dataLoading={loading}
                // @ts-ignore
                fetchData={filters => dispatch(fetchBillingInvoices(filters))}
                total={total}
                sortModel={[
                    {
                        field: 'invoice_creation_datetime',
                        sort: 'desc',
                    },
                ]}
                noDataMessage={"You don't have any invoices"}
            />
        </BillingLayout>
    );
};

export default Invoices;
