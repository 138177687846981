import './styles.scss';

import { useState } from 'react';

import RadioGroupCards from 'components/_common/RadioGroupCards';
import ProductCountryDropdown from 'components/_common/CountryDropdown/ProductCountryDropdown';
import Button from 'components/_common/Button';
import List from 'components/_common/List';
import TextInput from 'components/_common/TextInput';
import ProductHeader from './Items/ProductHeader';
import ProductPrices from './Items/ProductPrices';
import ProductSlider from './ProductSlider';

import { service_types } from 'helpers/services';
import { gb_max_real, ip_max } from './constants';

const getValue = (value, max) => (value >= max ? max : value <= 1 ? 1 : Number(value.toFixed(0)));

const ProductBox = ({
    productData,
    sliderValue,
    sliderChange,
    periodOptions,
    period,
    changePeriod,
    country,
    changeCountry,
    loadingSubmit,
    countriesOptions,
    isResidentialReconfigure,
    serviceId,
}) => {
    const { product_type, product_description, title, product_prices, product_id } = productData;

    const [isSlider, setValue] = useState(true);

    const isNotResidential = product_type !== service_types.residential;

    const button_text = isSlider ? 'Enter a custom quantity' : 'Select from the range';
    const input_value = isNotResidential ? sliderValue : sliderValue.realValue;

    const items = [
        <div>
            Residential Bandwidth <span className="bold-part">never expires</span>
        </div>,
        'You can pause your subscription at any time',
        'Residential Bandwidth remains on your account if you cancel or pause your subscription',
    ];

    const handleToggle = () => setValue(!isSlider);

    const handleChange = value => {
        if (isNotResidential) {
            const _value = getValue(value, ip_max);
            sliderChange(_value);
        } else {
            const _value = getValue(value, gb_max_real);
            sliderChange({ label: `${_value}GB`, realValue: _value, value: undefined });
        }
    };

    return (
        <div className="product-box box">
            <ProductHeader
                product_type={product_type}
                product_name={`${title} ${isResidentialReconfigure ? 'Top Up / Reconfiguration' : ''}`}
                product_description={
                    isResidentialReconfigure
                        ? `Reconfiguring residential bandwidth Service ${serviceId}`
                        : product_description
                }
            />
            <ProductPrices
                data={product_prices}
                type={product_type}
                isResidentialReconfigure={isResidentialReconfigure}
            />
            {isSlider ? (
                <ProductSlider
                    disabled={loadingSubmit}
                    sliderValue={sliderValue}
                    product_type={product_type}
                    onChange={sliderChange}
                />
            ) : (
                <TextInput
                    type="number"
                    label="Custom quantity"
                    value={input_value}
                    onChange={handleChange}
                    className="mb-24 mt-24"
                />
            )}
            <Button
                iconName={isSlider && 'edit'}
                variant="outlined"
                onClick={handleToggle}
                className="mb-24 slider-toogle-button"
            >
                {button_text}
            </Button>
            <RadioGroupCards
                label="Select subscription cycle"
                disabled={loadingSubmit}
                items={periodOptions}
                value={period}
                onChange={changePeriod}
            />
            {isNotResidential && (
                <ProductCountryDropdown
                    options={countriesOptions}
                    value={country}
                    onChange={changeCountry}
                    disabled={loadingSubmit}
                />
            )}
            {isNotResidential || (
                <div className="mt-24">
                    <List items={items} columns={1} />
                </div>
            )}
        </div>
    );
};

export default ProductBox;
