import React, { useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import Table from 'components/_common/Table';
import ServiceCell from 'components/_common/TableCells/ServiceCell';
import StatusCell from 'components/_common/TableCells/StatusCell';
import TextCell from 'components/_common/TableCells/TextCell';
import Label from 'components/_common/Label';
import PaymentCell from 'components/_common/TableCells/PaymentCell';
import ModalCancelSubscription from 'components/Modals/ModalCancelSubscription';
import ModalEditBilling from 'components/Modals/ModalEditBilling';
import ModalReconfigureService from 'components/Modals/ModalReconfigureService';
import ModalPauseUnpauseService from 'components/Modals/ModalPauseUnpauseService';
import { getParams } from 'components/GenerateViewProxies';

import { service_types, serviceStatuses } from 'helpers/services';
import ROUTES from 'helpers/routes';

import { ReactComponent as Icon2 } from 'icons/credit-card.svg';
import { ReactComponent as Icon3 } from 'icons/ban.svg';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ReactComponent as PauseIcon } from 'icons/pause.svg';

const OrdersTable = ({ data, dataLoading, fetchData, filters, total, noDataMessage }) => {
    const navigate = useNavigate();
    const [cancelOpen, setCancelOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [reconfigureOpen, setReconfigureOpen] = useState(false);
    const [pauseUnpauseOpen, setPauseUnpauseOpen] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                minWidth: 150,
                renderCell: ({ value }) => <StatusCell status={value} />,
            },
            {
                field: 'service',
                headerName: 'Service',
                minWidth: 350,
                flex: 1,
                renderCell: ({ value }) => <ServiceCell withFlag data={value} />,
            },
            {
                field: 'period',
                headerName: 'Period',
                flex: 1,
                minWidth: 100,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'payment_method_id',
                headerName: 'Payment Method',
                flex: 1,
                minWidth: 150,
                renderCell: ({ value, row }) =>
                    row?.auto_renewal ? (
                        <PaymentCell
                            type={row.payment_method_network}
                            num={row.payment_method_last_four}
                            method={row.transaction_method}
                        />
                    ) : (
                        <Label text={'Manual Payment'} />
                    ),
            },
            {
                field: 'next_invoice',
                headerName: 'Next Invoice',
                minWidth: 200,
                flex: 1,
                renderCell: ({ value, row }) => {
                    if (
                        row?.status === serviceStatuses.CANCELED ||
                        row?.status === serviceStatuses.COMPLETE ||
                        row?.status === serviceStatuses.OVERDUE
                    ) {
                        return null;
                    } else {
                        return <TextCell text={value} />;
                    }
                },
            },
            {
                field: 'service_total',
                headerName: 'Total',
                flex: 1,
                minWidth: 100,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                width: 50,
                getActions: params => {
                    const isResidential = params.row.type === service_types.residential;
                    const pullItems = [];
                    if (params.row.status === serviceStatuses.ACTIVE) {
                        pullItems.push(<GridActionsCellItem {...getParams(params, navigate)} />);
                        pullItems.push(
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                showInMenu
                                label="Reconfigure Service"
                                onClick={() => handleReconfigureOpen(params, isResidential)}
                            />,
                        );
                        if (isResidential) {
                            pullItems.push(
                                <GridActionsCellItem
                                    icon={<PauseIcon />}
                                    showInMenu
                                    label="Pause Subscription"
                                    onClick={() => handlePauseUnpauseSubscriptionOpen(params)}
                                />,
                            );
                        }
                    }
                    if (
                        params.row.service_subscription_id &&
                        ![serviceStatuses.CANCELED, serviceStatuses.COMPLETE].includes(params.row.status)
                    ) {
                        pullItems.push(
                            <GridActionsCellItem
                                icon={<Icon2 />}
                                showInMenu
                                label="Edit billing"
                                onClick={() => handleEditOpen(params)}
                            />,
                        );
                        pullItems.push(
                            <GridActionsCellItem
                                icon={<Icon3 />}
                                showInMenu
                                className="item-danger"
                                label="Cancel subscription"
                                onClick={() => handleCancelOpen(params)}
                            />,
                        );
                    }
                    return pullItems;
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleEditOpen = params => {
        setServiceData(params.row);
        setEditOpen(true);
    };
    const handleCancelOpen = params => {
        setServiceData(params.row);
        setCancelOpen(true);
    };
    const handleReconfigureOpen = (params, isResidential) => {
        if (isResidential) {
            return navigate(`${ROUTES.products}/${service_types.residential}`);
        }
        setServiceData(params.row);
        setReconfigureOpen(true);
    };
    const handlePauseUnpauseSubscriptionOpen = params => {
        setServiceData(params.row);
        setPauseUnpauseOpen(true);
    };

    return (
        <>
            <Table
                lastColSticky
                columns={columns}
                rows={data}
                rowHeight={56}
                dataLoading={dataLoading}
                fetchData={fetchData}
                filters={filters}
                total={total}
                noDataMessage={noDataMessage}
            />
            <ModalEditBilling setOpen={setEditOpen} open={editOpen} data={serviceData} type="orders" />
            <ModalCancelSubscription setOpen={setCancelOpen} open={cancelOpen} data={serviceData} />
            <ModalReconfigureService setOpen={setReconfigureOpen} open={reconfigureOpen} data={serviceData} />
            <ModalPauseUnpauseService
                setOpen={setPauseUnpauseOpen}
                open={pauseUnpauseOpen}
                service_id={serviceData?.id}
                onClick={() => fetchData({ page: 1, per_page: 10 })}
                isPause={serviceData?.status === 'active'}
            />
        </>
    );
};

export default OrdersTable;
