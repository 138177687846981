import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DashboardBandwidth from '../../DashboardBandwidth';
import MessageCard from 'components/MessageCard';
import ModalUnpauseService from 'components/Modals/ModalPauseUnpauseService';

import { selectMetrics } from 'store/slices/services';
import { selectActiveResidentialServiceId } from 'store/slices/customer';

import ROUTES from 'helpers/routes';
import { service_types } from 'helpers/services';

const ResidentalProxiesTab = () => {
    const navigate = useNavigate();
    const metrics = useSelector(selectMetrics);
    const active_residential_service_id = useSelector(selectActiveResidentialServiceId);

    const [isUnpauseModalOpen, setIsUnpauseModalOpen] = useState(false);

    return (
        <>
            {metrics?.find(metric => metric.title === 'usage')?.isNeedTopUp ? (
                <div className="mb-24">
                    <MessageCard
                        buttonText="Top Up"
                        customStyles={{ display: 'flex', gap: 5, height: 100 }}
                        customButtonStyles={{ display: 'block', width: 200, marginLeft: 'auto' }}
                        type="bad"
                        message="You are close to running out of bandwidth, top up now to continue using Residential proxies."
                        onClick={() => navigate(`${ROUTES.products}/${service_types.residential}`)}
                    />
                </div>
            ) : null}
            <DashboardBandwidth />
            <ModalUnpauseService
                open={isUnpauseModalOpen}
                setOpen={setIsUnpauseModalOpen}
                customContent={<p>Service is paused. Please unpause the service to reconfigure.</p>}
                service_id={active_residential_service_id}
                onClick={() => navigate(`${ROUTES.products}/${service_types.residential}`)}
            />
        </>
    );
};

export default ResidentalProxiesTab;
